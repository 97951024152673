import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import _ from "lodash";

import Button from "components/Form/Button/Button";
import Spinner from "components/Spinner/Spinner";

import { getStory } from "api/stories";

const StoryWidget = ({
  storyId,
  className = "",
  hintText = "",
  readOnly = false,
  onSubmit = null,
}) => {
  const [story, setStory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!storyId) return;

    setIsLoading(true);

    const loadStory = async () => {
      try {
        const response = await getStory(storyId);

        if (response.code === "ERR_BAD_REQUEST") {
          toast.error(
            `${t(response.response.data.detail)}\nStory ID: ${storyId}`,
          );
        } else {
          setStory(response);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadStory();
  }, [storyId, t]);

  return (
    <div
      className={twMerge(
        "story-widget h-[8.75rem] flex flex-col justify-start relative border rounded font-sm bg-slate-100",
        className,
      )}
    >
      <Spinner isLoading={isLoading} />

      {_.isEmpty(story) && !readOnly && !isLoading && (
        <div className="no-story flex flex-col my-auto self-center">
          {hintText && <p className="mb-2 text-sm">{hintText}</p>}
          <Button className="btn-primary" onClick={onSubmit}>
            {t("Select story")}
          </Button>
        </div>
      )}

      {_.isEmpty(story) && readOnly && (
        <div className="flex justify-center self-center my-auto">
          <p className="whitespace-pre-wrap text-slate-500 text-sm">
            {t("No case selected.")}
          </p>
        </div>
      )}

      {!_.isEmpty(story) && (
        <>
          {!readOnly && (
            <div className="absolute right-2 top-2">
              <Button className="btn-action" onClick={onSubmit}>
                <i className="ri-pencil-line"></i>
              </Button>
            </div>
          )}
          <div className="p-4 overflow-auto">
            <p className="max-w-4xl text-sm leading-relaxed">
              {story?.description}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default StoryWidget;

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import _ from "lodash";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import Spinner from "components/Spinner/Spinner";

import { getPatient } from "api/patients";

import { addLeadingZeros, formatDate } from "utils/utils";

const PatientWidget = ({
  patientId,
  className = "",
  hintText = "",
  readOnly = false,
  onSubmit = null,
}) => {
  const [patient, setPatient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!patientId) return;

    setIsLoading(true);

    const loadPatient = async () => {
      try {
        const response = await getPatient(patientId);

        if (response.code === "ERR_BAD_REQUEST") {
          toast.error(
            `${t(response.response.data.detail)}\nPatient ID: ${patientId}`,
          );
        } else {
          setPatient(response);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPatient();
  }, [patientId, t]);

  const historyBadges = patient?.patient_history
    ?.filter((item) => item.description) // filters out items without descriptions
    .map((item, idx) => (
      <Badge key={idx} className="bg-slate-400" value={item.description} />
    ));
  if (historyBadges?.length === 0) historyBadges[0] = "-";

  return (
    <div
      className={twMerge(
        "patient-widget min-h-[8.75rem] px-4 py-2 flex flex-col justify-start relative bg-slate-100 border rounded font-sm",
        className,
      )}
    >
      <Spinner isLoading={isLoading} />

      {_.isEmpty(patient) && !readOnly && !isLoading && (
        <div className="no-patient flex flex-col my-auto self-center">
          {hintText && <p className="mb-2 text-sm">{hintText}</p>}
          <Button className="btn-primary" onClick={onSubmit}>
            {t("Select patient")}
          </Button>
        </div>
      )}

      {_.isEmpty(patient) && readOnly && (
        <div className="flex justify-center self-center my-auto">
          <p className="whitespace-pre-wrap text-slate-500 text-sm">
            {t("No patient selected.")}
          </p>
        </div>
      )}

      {!_.isEmpty(patient) && (
        <>
          {!readOnly && (
            <div className="absolute right-2 top-2">
              <Button className="btn-action" onClick={onSubmit}>
                <i className="ri-pencil-line"></i>
              </Button>
            </div>
          )}
          <div className="grid grid-rows-4 grid-flow-col gap-x-4 [&_label]:w-32 [&_label]:text-xxs [&_label]:font-bold">
            <div className="flex items-center">
              <label>{t("Patient ID")}</label>
              <p>{addLeadingZeros(patient?.patient.id, 5)}</p>
            </div>
            <div className="flex items-center">
              <label>{t("First name")}</label>
              <p>{patient?.patient.first_name}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Last name")}</label>
              <p>{patient?.patient.last_name}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Date of birth")}</label>
              <p>{formatDate(patient?.patient.birth_date)}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Age")}</label>
              <p>{patient?.patient.age}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Sex")}</label>
              <p>{t(patient?.patient.gender)}</p>
            </div>
            <div className="flex items-center">
              <label>{t("Body weight")}</label>
              <p>
                {t("number", { val: patient?.patient.body_weight }) || "-"}{" "}
                {t(patient?.patient.body_unit)}
              </p>
            </div>
            <div className="flex items-center">
              <label>{t("eGFR")}</label>
              <p>
                {t("number", { val: patient?.patient.kidney_function }) || "-"}{" "}
                {patient?.patient.kidney_function && t("ml/min/1.73m²")}
              </p>
            </div>
            <div className="flex items-center mb-2 whitespace-nowrap">
              <label>{t("Medical history")}</label>
            </div>
            <div className="history row-span-2 flex flex-wrap gap-2 self-start mb-2 text-sm overflow-y-auto">
              {patient?.patient_history?.length > 0
                ? patient?.patient_history
                    ?.filter((item) => item.description) // filters out items without descriptions
                    .map((item, idx) => (
                      <Badge
                        key={idx}
                        className="!m-0 bg-slate-400"
                        value={item.description}
                      />
                    ))
                : "-"}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientWidget;

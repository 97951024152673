import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import jwtDecode from "jwt-decode";
// import { format } from "date-fns";

import { isJwt, parseJwt } from "utils/utils";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authData, _setAuthData] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [isTokenExpiring, setIsTokenExpiring] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(true);

  const isAuthenticated =
    authData &&
    parseJwt(authData.access_token)?.exp > Math.floor(Date.now() / 1000);

  const setAuthData = (token) => {
    _setAuthData(token);
    setIsTokenExpired(false);
    setIsTokenExpiring(false);
  };

  const clearAuthData = () => {
    _setAuthData(null);
    localStorage.removeItem("authData");
    setIsTokenExpired(true);
    setIsTokenExpiring(false);
  };

  const checkTokenStatus = useCallback(() => {
    // console.log("checking token status");
    if (!authData) return false;

    try {
      // const delta = 2.5 * 60 * 60; // 2h30min in seconds
      const delta = 1 * 60 * 60; // 1h in seconds
      const expiringTreshold = 30; // 30 seconds
      const decodedToken = jwtDecode(authData?.access_token);
      const tokenExpiration = decodedToken.exp; // unix time in seconds
      const currentTime = Math.floor(Date.now() / 1000); //current time in seconds
      const timeLeft = tokenExpiration - currentTime - delta;

      /*
      console.log(
        "time left (sec)",
        format(new Date(timeLeft * 1000), "HH:mm:ss"),
      );
      */

      if (timeLeft < 0) {
        setIsTokenExpired(true);
        setIsTokenExpiring(false);
      } else {
        setIsTokenExpired(false);
        setIsTokenExpiring(timeLeft <= expiringTreshold);
      }
    } catch (error) {
      console.error("Invalid token:", error);
      return false;
    }
  }, [authData]);

  // check the status of the token every 10 seconds

  useEffect(() => {
    const interval = setInterval(checkTokenStatus, 10 * 1000);
    // cleanup on unmount
    return () => clearInterval(interval);
  });

  // load auth data from local storage on mount

  useEffect(() => {
    // console.log("load data from local storage");
    setIsAuthLoading(true);
    try {
      const savedAuthData = JSON.parse(localStorage.getItem("authData"));

      if (!savedAuthData) {
        console.log("No auth data found");
        setIsAuthLoading(false);
        return;
      }

      const currentTimestamp = Math.floor(Date.now() / 1000);
      const tokenPayload = parseJwt(savedAuthData.access_token);
      const isValid = tokenPayload?.exp > currentTimestamp;

      // console.log("Token is valid:", isValid);

      // console.log("Token is expiring:", isTokenExpiring);

      if (isValid) {
        setAuthData(savedAuthData);
      } else {
        console.log("not valid, logging out");
        clearAuthData();
      }
    } catch (error) {
      console.error("Error loading auth data from local storage:", error);
      clearAuthData();
    } finally {
      setIsAuthLoading(false);
    }
  }, []);

  // save auth data to local storage whenever it changes

  useEffect(() => {
    if (authData) {
      localStorage.setItem("authData", JSON.stringify(authData));

      // automatic logout on token expiration

      // isJwt is checking if the token is a valid JWT
      // password reset tokens are not JWTs

      if (isJwt(authData?.access_token)) {
        const { exp } = parseJwt(authData?.access_token);
        const timeout = exp * 1000 - Date.now();
        if (timeout > 0) {
          const timer = setTimeout(clearAuthData, timeout);
          return () => clearTimeout(timer);
        }
      }
    } else {
      localStorage.removeItem("authData");
    }
  }, [authData]);

  return (
    <AuthContext.Provider
      value={{
        authData,
        setAuthData,
        clearAuthData,
        isTokenExpired,
        isTokenExpiring,
        isAuthLoading,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuth };

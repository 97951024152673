import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { useUser } from "context/UserContext";

import Button, { ButtonGroup } from "components/Form/Button/Button";
import Select from "components/Form/Select/Select";
import Textarea from "components/Form/Textarea/Textarea";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

import { getGrades } from "api/grades";

import { formatDate } from "utils/utils";

import "./ReviewForm.scss";

const ReviewForm = ({
	className,
	target,
	title = "Review",
	helpText,
	readOnly,
	reviewCaption = "Review",
	submitCaption = "Save",
	cancelCaption = "Cancel",
	onClose,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const { canI } = useUser();

	const [isEditMode, setIsEditMode] = useState(false);
	const [grade, setGrade] = useState(null);
	const [notes, setNotes] = useState("");
	const [gradeOptions, setGradeOptions] = useState([]);

	const formatGradeOptions = (gradeData) => {
		const gradeOptions = [];
		gradeData.forEach((grade) => {
			gradeOptions.push({ value: grade.id, label: t(grade.description) });
		});
		return gradeOptions;
	};

	const handleClose = () => {
		setIsEditMode(!isEditMode);
		if (onClose) onClose();
	};

	const handleReview = () => {
		setIsEditMode(!isEditMode);
	};

	const handleSubmit = () => {
		if (onSubmit) onSubmit({ grade, notes });
		setIsEditMode(!isEditMode);
	};

	// get the grades and populate the dropdown

	useEffect(() => {
		(async () => {
			const gradeData = await getGrades();
			if (gradeData === "ERR_BAD_REQUEST") {
				toast.error(t(gradeData.response.data.detail));
				console.log(gradeData);
			} else {
				setGradeOptions(gradeData);
			}
		})();
	}, [t]);

	useEffect(() => {
		setGrade({
			value: target.grade_id,
			label: t(target.grade),
		});
		setNotes(target.reviewer_notes);
	}, [target, t]);

	return (
		<div className="p-4 border border border-neutral-200 rounded shadow bg-neutral-100 flex flex-col gap-8">
			<div className="pb-1 border-b border-neutral-200 flex gap-2 items-center">
				<h3>{title}</h3>
				{helpText && <HelpTooltip text={helpText} />}
			</div>
			<form className={twMerge("review-form", className)}>
				<div className="grid grid-cols-2 gap-2 text-sm">
					<strong>{t("Grade")}</strong>
					<div className="has-select p-0 align-middle h-[32px]">
						<Select
							id="unit"
							value={grade}
							noneLabel={t("Not graded")}
							options={formatGradeOptions(gradeOptions)}
							readOnly={!isEditMode}
							focused={isEditMode}
							onChange={(value) => setGrade(value)}
						/>
					</div>
					<strong className="col-span-2">{t("Comments")}</strong>
					<div className="h-28 col-span-2">
						{isEditMode ? (
							<Textarea
								className="h-full"
								value={notes || ""}
								readOnly={!isEditMode}
								onChange={(e) => setNotes(e.target.value)}
							/>
						) : (
							<p className="h-28 ps-1 mb-4 rounded col-span-2 text-slate-500 whitespace-pre-line overflow-auto">
								{notes || ""}
							</p>
						)}
					</div>
					<hr className="my-2 col-span-2" />
					<div className="text-gray-500">{t("Reviewer")}</div>
					<div className="text-gray-500">{target.reviewer || "-"}</div>
					<div className="text-gray-500">{t("Date reviewed")}</div>
					<div className="text-gray-500">
						{formatDate(target.reviewed_at) || "-"}
					</div>
				</div>
				<div>
					{canI("review", "SingleSession") &&
						target?.total_treatment_pending > 0 && (
							<p className="p-1 ps-2 mt-8 text-sm rounded col-span-2 bg-neutral-200 flex gap-2 items-baseline">
								<i className="ri-information-line"></i>
								{t(
									target.total_treatment_pending === 1
										? "You have 1 unreviewed case in this session."
										: "You have {{total}} unreviewed cases in this session.",
									{
										total: target.total_treatment_pending,
									},
								)}
							</p>
						)}

					{canI("review", "SingleSession") &&
						target?.total_prescription_pending > 0 && (
							<p className="col-span-2 message__note my-6">
								{target?.total_prescription_pending > 1 &&
									`${target?.total_prescription_pending} ${t(
										"prescriptions still need to be reviewed before reviewing this treatment plan.",
									)}`}
								{target?.total_prescription_pending === 1 &&
									t(
										"One prescription still needs to be reviewed before reviewing this treatment plan.",
									)}
							</p>
						)}

					{canI("review", "SingleSession") && (
						<div className="col-span-2">
							<ButtonGroup className="justify-end">
								{!isEditMode ? (
									<Button
										className={`btn-primary min-w-24 p-2 ${
											(readOnly || target?.total_treatment_pending < 0) &&
											"disabled"
										}`}
										onClick={handleReview}
									>
										{reviewCaption}
									</Button>
								) : (
									<>
										<Button
											className="min-w-fit"
											onClick={handleClose}
											title={t(cancelCaption)}
										>
											{t(cancelCaption)}
										</Button>
										<Button
											className={`btn-primary min-w-24 p-2 ${
												readOnly && "disabled"
											} ${!grade && "disabled"} `}
											onClick={handleSubmit}
										>
											{t(submitCaption)}
										</Button>
									</>
								)}
							</ButtonGroup>
						</div>
					)}
				</div>
			</form>
		</div>
	);
};

export default ReviewForm;

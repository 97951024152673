import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import Table, { Actions } from "components/Table/Table";
import UserModal from "components/UserModal";
import ConfirmationModal from "components/ConfirmationModal";

import { getUsers, updateUser, deleteUser } from "api/users";

import { formatDate } from "utils/utils";

const Users = () => {
	// modals - states
	const [showEditUserModal, setShowEditUserModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// hooks
	const { t } = useTranslation();

	// table - states
	const [users, setUsers] = useState([]);
	const [refreshTable, setRefreshTable] = useState(true);
	const [userId, setUserId] = useState();
	const [userEmail, setUserEmail] = useState();
	const [userRoleId, setUserRoleId] = useState();
	const [userIsBlocked, setUserIsBlocked] = useState();

	// table - actions

	const rowActions = useMemo(
		() => [
			{
				name: "edit",
				icon: "ri-pencil-line",
				command: handleEdit,
				title: t("Edit user"),
			},
			{
				name: "delete",
				icon: "ri-delete-bin-line",
				command: handleDelete,
				title: t("Delete user"),
			},
		],
		[t],
	);

	// table - columns

	const columns = useMemo(
		() =>
			[
				{
					Header: t("E-mail"),
					accessor: "email",
				},
				{
					Header: t("First name"),
					accessor: "first_name",
				},
				{
					Header: t("Last name"),
					accessor: "last_name",
				},
				{
					Header: t("Role"),
					accessor: "user_role",
					cssClass: "text-center",
					Cell: ({ cell: { value } }) => t(value),
				},
				{
					Header: t("Created at"),
					accessor: "created_at",
					cssClass: "text-center whitespace-nowrap",
					Cell: ({ cell: { value } }) => formatDate(value, true),
				},
				{
					Header: t("Last login"),
					accessor: "last_login",
					cssClass: "text-center whitespace-nowrap",
					Cell: ({ cell: { value } }) => formatDate(value, true),
				},
				{
					Header: t("Status"),
					accessor: "is_blocked",
					cssClass: "text-center min-cell-width",
					Cell: ({ cell: { value } }) => (value ? t("Blocked") : t("Active")),
				},
				{
					Header: t("Confirmation code"),
					accessor: "code",
					cssClass: "text-center",
				},
				{
					Header: t("Confirmed account"),
					accessor: "is_confirmed",
					cssClass: "text-center min-cell-width",
					Cell: ({ cell: { value } }) =>
						value ? (
							<i
								className="ri-check-line text-green-500"
								title={t("Confirmed")}
							></i>
						) : (
							t("Not confirmed")
						),
				},
				rowActions.length > 0 && {
					Header: t("Actions"),
					accessor: "accessor",
					disableSortBy: true,
					cssClass: "actions text-center min-cell-width pr-5",
					Cell: ({ row: { original } }) =>
						rowActions && <Actions row={original} actions={rowActions} />,
				},
			].filter((item) => item !== false),
		[rowActions, t],
	);

	// table - action handlers

	function handleEdit(row) {
		setUserId(row?.id);
		setUserEmail(row?.email);
		setUserRoleId(row?.user_role_id);
		setUserIsBlocked(row?.is_blocked || false);
		setShowEditUserModal(true);
	}

	function handleDelete({ id, email }) {
		setUserId(id);
		setUserEmail(email);
		setShowDeleteModal(true);
	}

	const handleDeleteConfirmed = async () => {
		setShowDeleteModal(false);
		try {
			const response = await deleteUser(userId);
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
				console.log(response);
			} else {
				toast.success(t("User deleted!"));
				setUserId(null);
				setRefreshTable(true);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const handleSubmit = async ({ roleId, isBlocked }) => {
		try {
			const response = await updateUser({
				id: userId,
				roleId,
				isBlocked,
			});
			if (response.code === "ERR_BAD_REQUEST") {
				toast.error(t(response.response.data.detail));
			} else {
				toast.success(t("User updated!"));
				setRefreshTable(true);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const applyFilters = (data) => {
		// return all elements
		return data.filter((object) => true);
	};

	// fetch data

	useEffect(() => {
		if (!refreshTable) return;

		const fetchUsers = async () => {
			try {
				const response = await getUsers();

				if (response.code === "ERR_BAD_REQUEST") {
					toast.error(t(response.response.data.detail));
					console.log(response);
				} else {
					setUsers(applyFilters(response));
				}
			} catch (error) {
				console.error("An error occurred:", error);
			} finally {
				setRefreshTable(false);
			}
		};

		fetchUsers();
	}, [refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Users")}</h1>
					<HelpTooltip
						text={t(`List of all users. Administrators can here block users.`)}
					/>
				</div>

				<div className="content">
					<Table
						data={users}
						columns={columns}
						actions={rowActions}
						searchLabel={t("Search by e-mail, first or last name")}
						searchColumns={["email", "first_name", "last_name"]}
						selectedId={userId}
						onRowClick={(row) => handleEdit(row.original)}
					/>
				</div>
			</div>

			{showEditUserModal && (
				<UserModal
					user={userEmail}
					roleId={userRoleId}
					isBlocked={userIsBlocked}
					onClose={() => {
						setUserId(null);
						setShowEditUserModal(false);
					}}
					onSubmit={handleSubmit}
				/>
			)}

			{showDeleteModal && (
				<ConfirmationModal
					title={t("Delete user?")}
					message={`${t(
						"Are you sure you want to delete the user",
					)} ${userEmail} (${userId})?`}
					yesMessage={t("Delete")}
					destructive={true}
					onClose={() => setShowDeleteModal(false)}
					onSubmit={handleDeleteConfirmed}
				/>
			)}
		</>
	);
};

Users.displayName = "Users";

export default Users;

import { useTranslation } from "react-i18next";

import Badge from "components/Badge/Badge";

import { formatDate } from "utils/utils";

const SingleSessionHeader = ({ session, userRole = "" }) => {
	const { t } = useTranslation();

	return (
		<div className="header ml-8 pb-2 border-b">
			<span className="font-light text-xl">[{t("Session")}]</span>
			<h1>{session.description}</h1>
			<Badge className="ms-2 -mt-[2px] text-xxs" value={session.status} />
			<div className="meta ml-auto -mb-[3px] text-sm self-end flex gap-5 text-gray-500 whitespace-nowrap">
				<div title={t("Session ID")}>
					{t("Session ID")} {session.id}
				</div>
				{session.session_type === "Self-Study" ? (
					<div title={t("Session type")}>{t("Self-Study")}</div>
				) : (
					<>
						<div title={t("Class ID")}>
							{`${t("Class ID")} ${session.class_id}`}
						</div>
						<div title={t("Class author")}>
							<i className="ri-account-circle-line"></i> {session.class_creator}
						</div>
					</>
				)}
				{userRole === "Teacher" && (
					<div title={t("Author")}>
						<i className="ri-account-circle-line"></i> {session.creator}
					</div>
				)}
				<div title={t("Session created on")}>
					<i className="ri-calendar-line"></i> {formatDate(session.created_at)}
				</div>
			</div>
		</div>
	);
};

export default SingleSessionHeader;

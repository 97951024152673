import { parse, format, isValid } from "date-fns";

export const formatDate = (inputDate, showTime = false) => {
  if (!inputDate) return;

  const date = new Date(inputDate);

  // ignore default date or placeholders
  if (date.getTime() === new Date("1970-01-01").getTime()) return;

  if (isNaN(date)) {
    return "Invalid date format";
    // throw new Error("Invalid date");
  }

  const dateStr = showTime
    ? date.toLocaleString("nl-NL", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
    : date.toLocaleString(["nl-NL"], {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });

  return dateStr;
};

// change the format of a date string
export const convertDateFormat = (date, patternIn, patternOut) => {
  if (!(date && patternIn && patternOut)) return;

  const dateObj = parse(date, patternIn, new Date());

  if (!isValid(dateObj)) return;

  return format(dateObj, patternOut);
};

export const addLeadingZeros = (number, zeros = 5) => {
  number = String(number);
  return number.padStart(zeros, "0");
};

/**
 * check if the token is a JWT
 * the password reset token is not a JWT
 */
export const isJwt = (token) => {
  if (!token) return false;
  const parts = token.split(".");
  return parts.length === 3;
};

export const parseJwt = (token) => {
  if (!token || typeof token !== "string" || token.split(".").length < 3) {
    console.error("Invalid JWT token");
    return null;
  }

  try {
    const base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(atob(base64));
  } catch (error) {
    console.error("Error parsing JWT:", error);
    return null;
  }
};

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";

const onClose = () => {
  window.opener = null;
  window.open("", "_self");
  window.close();
};

/**
 * Terms and conditions view
 */
const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-page mb-5 max-w-[82ch]">
      <h1>Privacy Policy</h1>

      <p>
        <i>
          Wilt u een Nederlandse versie van de Privacy Policy, neem dan contact
          met ons op.
        </i>
      </p>

      <p>
        <i>Effective date: 27-01-2025</i>
      </p>

      <p>
        At <strong>Edups B.V.</strong> we (“Edups”, “we”, “us”, “our”) are
        committed to respecting and protecting your privacy. This privacy policy
        explains how we collect, use, disclose, and safeguard your personal
        information when you visit our website, use our services, or otherwise
        interact with us.
      </p>

      <p>
        By accessing or using our Services, you signify that you have read,
        understood, and agree to our collection, storage and use of your
        personal information as described in this Privacy Policy in addition to
        any terms described in the General Terms and Conditions. If you do not
        agree, please discontinue use of the Services immediately.
      </p>

      <h3>Change log</h3>
      <ul>
        <li>27 Jan 2025 - First version</li>
      </ul>

      <h2>Scope</h2>

      <p>
        This Privacy Policy applies to personal information we collect through:
      </p>
      <ul>
        <li>
          Our website(s), including{" "}
          <Link to="https://edups.nl/" title="Edups homepage" target="_blank">
            https://edups.nl
          </Link>{" "}
          and its subdomains or any other website we operate (the "Website")
        </li>
        <li>Our online and offline services (collectively, the "Services")</li>
        <li>
          Any other interactions you may have with Edups B.V., such as customer
          service inquiries, marketing events, or business collaborations.
        </li>
      </ul>

      <h2>Information we collect</h2>

      <h3>Client Data</h3>

      <p>
        Any content and information submitted by users to the services is
        referred to in this policy as “Client Data”. The client that is an
        organization uses a Service and sets up an organization-specific
        environment and is hereafter referred to as the “Client”. Client Data is
        controlled by the Client. If you access such an environment through a
        user account, you are a “User”.
      </p>

      <p>
        The Client determines their own policies regarding storage, access,
        modification, deletion, sharing, and retention of Client Data. If you
        are using the Services by invitation of a Client, regardless of your
        relationship to them, their policies apply. If you are using the
        Services by invitation of Edups - for example during a pilot, Edups
        reserves all rights to any data or information that bears any similarity
        to Client Data.
      </p>

      <h3>Other information</h3>

      <p>We may collect the following categories of personal information:</p>
      <ul>
        <li>
          <strong>Account credentials:</strong> When you create an account, you
          provide us with information such as an email address and password.
        </li>
        <li>
          <strong>Contact information:</strong> With your permission, any
          contact information you provide us with. This includes name, email
          address and phone number.
        </li>
        <li>
          <strong>Setup information:</strong> If you are an organization using
          our services, any information that is related to setting up the
          Service for your organization.
        </li>
        <li>
          <strong>Payment information:</strong> Billing address and payment
          details (e.g. credit/debit card number, etc.) if you purchase our
          Services. This information may be shared with our corporate affiliates
          and our third-party payment processor(s).
        </li>
        <li>
          <strong>Usage information:</strong> Information about how you use the
          Services, including but not limited to the device used, Internet
          Protocol address, browser type, pages visited, and the time spent on
          our Website.
        </li>
        <li>
          <strong>Communications:</strong> Information you provide when
          contacting us, responding to surveys, or participating in promotions.
        </li>
        <li>
          <strong>Other information:</strong> Any other information that you
          choose to provide us voluntarily.
        </li>
      </ul>

      <h2>How we collect your information</h2>

      <p>We collect information in several ways, including:</p>
      <ol>
        <li>
          <strong>Directly from you</strong>: For example, when you register for
          an account, fill out forms, sign up for our newsletter, or communicate
          with us.
        </li>
        <li>
          <strong>Automatically:</strong> Through cookies, log files, and
          similar tracking technologies when you use our Services.
        </li>
        <li>
          <strong>From Third Parties:</strong> We may receive information from
          service providers, partners, payment processors, or publicly available
          sources.
        </li>
      </ol>

      <h2>How we use your information</h2>

      <h3>Client Data</h3>

      <p>Edups may access and use Client Data as reasonably necessary to:</p>
      <ol>
        <li>provide, maintain, and improve the Service(s),</li>
        <li>
          to prevent or address service, security, technical issues or at a
          Client’s request for customer support matters,
        </li>
        <li>for analytics and research purposes,</li>
        <li>for marketing purposes and to publish (scientific) articles</li>
        <li>as required by law, and</li>
        <li>
          as set forth in our agreement with the Client or as explicitly
          permitted in writing by the Client.
        </li>
      </ol>

      <h3>Other information</h3>

      <p>We use the collected information for various purposes, including:</p>

      <ol>
        <li>
          <strong>Service Delivery</strong>: To provide, maintain, and improve
          our Services, process transactions, and communicate with you about the
          Services.
        </li>
        <li>
          <strong>User Account Management</strong>: To create and manage your
          account, respond to support inquiries, and provide customer service.
        </li>
        <li>
          <strong>Marketing and Communications</strong>: To send you promotional
          materials, newsletters, or updates about Edups, where allowed by law
          and/or with your consent.
        </li>
        <li>
          <strong>Analytics and Personalization</strong>: To analyze usage of
          our Services, personalize content, and improve user experience.
        </li>
        <li>
          <strong>Compliance and Protection</strong>: To comply with legal
          obligations, respond to legal requests, protect the rights and safety
          of our users and others, and enforce our agreements.
        </li>
        <li>
          <strong>Other Purposes</strong>: We may use your information for
          additional purposes disclosed to you at the time of collection or with
          your consent.
        </li>
      </ol>

      <h2>Cookies and tracking technologies</h2>
      <p>
        We may use cookies, web beacons, and similar tracking technologies to
        collect information about your interactions with our Website. This helps
        us remember your preferences, customize content, track usage trends, and
        improve the Website. You can manage your cookie preferences through your
        browser settings, but disabling cookies may affect the functionality of
        the Website or our Services.
      </p>

      <h2>How we share your information</h2>
      <p>
        There are times where your information, including Client Data, may be
        shared by us:
      </p>

      <h3>Client Data</h3>
      <p>
        Edups may share Client Data in accordance with this Privacy Policy, our
        agreement with the Client, and the Client’s instructions. This includes
        sharing Client Data with third party service providers and agents and
        affiliates.
      </p>

      <h3>Other information</h3>
      <p>
        Edups may share information about you with the Client by whose
        invitation you are using our services. This is in order to help resolve
        any issue with the Service(s) that either you or the Client may have
        encountered.
      </p>

      <p>
        We may engage third-party companies or individuals to process
        information on our behalf. (e.g., payment processors, hosting services,
        etc.).
      </p>

      <h3>Other types of disclosures</h3>
      <p>Edups may share or disclose Client Data and other information:</p>
      <ul>
        <li>
          <strong>During changes to our business structure:</strong> For example
          if we engage in a merger, acquisition, bankruptcy, dissolution,
          reorganization, sale of some or all of our assets, financing,
          acquisition of all or a portion of our business, a similar transaction
          or proceeding, or steps in contemplation of such activities (e.g. due
          diligence).
        </li>
        <li>
          <strong>To comply with laws</strong>: We may disclose your information
          in compliance with legal or regulatory requirements and to respond to
          lawful requests, court orders, and legal processes.
        </li>
        <li>
          <strong>To protect our rights:</strong> We may disclose your
          information when we believe it is necessary to protect our rights,
          property, or safety or that of our employees, clients, or others.
        </li>
      </ul>
      <p>
        We may disclose or use aggregate or de-identified information for any
        purpose, including research and marketing purposes.
      </p>

      <h2>Data retention</h2>
      <p>
        In accordance with the General Data Protection Regulation (GDPR), we do
        not store data longer than necessary:
      </p>

      <h3>Client Data</h3>
      <p>
        The retention of Client Data is specified in our agreement with the
        Client. De-identified Client Data will remain the property of Edups for
        an indefinite period.
      </p>

      <h3>Other information</h3>
      <p>
        Edups stores its logs for 365 days, unless legal obligations prescribe
        that the log files are required, or for research in the context of a
        (suspected) security incident. During this retention period, clients may
        request access to this information. Please note that, if a client
        requests access to log information beyond what is typically included in
        our services, an hourly rate may apply for the time spent fulfilling
        this request.
      </p>

      <p>
        If you have questions about data retention periods specific to your
        situation or your rights to erasure, please contact us at{" "}
        <Link to="mailto:privacy@edups.nl">privacy@edups.nl</Link>.
      </p>

      <h2>Security Measures</h2>
      <p>
        We implement various technical and organizational measures to protect
        your information from unauthorized access, disclosure, alteration or
        destruction. However, no security system is impenetrable, and we cannot
        guarantee the absolute security of your information.
      </p>

      <h2>Your rights and choices</h2>
      <p>
        Depending on your location and applicable laws, you may have the
        following rights regarding your information:
      </p>
      <ol>
        <li>
          <strong>Right to Access</strong>: Request a copy of the information we
          hold about you.
        </li>
        <li>
          <strong>Right to Rectification</strong>: Request that we correct any
          inaccuracies in your personal information.
        </li>
        <li>
          <strong>Right to Erasure</strong>: Request that we delete your
          information when it is no longer needed.
        </li>
        <li>
          <strong>Right to Restrict Processing</strong>: Ask us to restrict the
          processing of your information in certain circumstances.
        </li>
        <li>
          <strong>Right to Data Portability</strong>: Request a copy of your
          information in a structured, commonly used, and machine-readable
          format.
        </li>
        <li>
          <strong>Right to Object</strong>: Object to certain processing (e.g.,
          direct marketing).
        </li>
        <li>
          <strong>Right to Withdraw Consent</strong>: Where you have provided
          consent, you may withdraw it at any time.
        </li>
        <li>
          <strong>Lodge a Complaint</strong>: You have the right to complain to
          a data protection authority if you believe we are violating your
          rights.
        </li>
      </ol>
      <p>
        Edups reserves the right to retain any log-data and de-identified Client
        Data.
      </p>
      <p>
        Do you wish to exercise your rights or have any questions regarding
        them? Please contact us at{" "}
        <Link to="mailto:privacy@edups.nl">privacy@edups.nl</Link>.
      </p>

      <h2>Minors’ information</h2>
      <p>
        Our Services are not directed towards children under the age of 16, and
        we do not knowingly collect information from them. If you believe a
        child has provided us with personal information, please contact us
        immediately so we can take appropriate measures.
      </p>

      <h2>Links to third-party websites</h2>
      <p>
        Our Services may contain links to other websites not operated or
        controlled by Edups. This Privacy Policy does not apply to those
        third-party websites. We encourage you to review the privacy policies of
        any third-party sites you visit.
      </p>

      <h2>Changes</h2>
      <p>
        Our Privacy Policy is subject to change. When we make changes, we will
        revise the “Effective Date” at the top. We encourage you to review this
        Privacy Policy periodically to stay informed of any updates. If we make
        any material changes, we will notify you via email or a prominent notice
        on our Website prior to the changes becoming effective. If you continue
        to use our Services after those changes are in effect, you agree to the
        revised policy.
      </p>

      <h2>Contact us</h2>
      <p>
        If you have any questions about this Privacy Policy or practices, feel
        free to contact us. You may contact us at{" "}
        <Link to="mailto:privacy@edups.nl">privacy@edups.nl</Link>.
      </p>

      <div className="my-8 text-center">
        <Button className="btn-primary" onClick={onClose}>
          {t("Close")}
        </Button>
      </div>
    </div>
  );
};

PrivacyPolicy.displayName = "PrivacyPolicy";

export default PrivacyPolicy;

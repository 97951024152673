import axiosInstance from "api/axiosInstance";

/**
 *  GET /packaging-unit
 *
 *  get all packaging units
 */
export const getPackagingUnits = async () => {
	return await axiosInstance
		.get("/packaging-unit")
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

/**
 *  GET /packaging-unit/{packaging_unit}
 *
 *  get a packaging unit by id
 */
export const getPackagingUnit = async (id) => {
	return await axiosInstance
		.get(`/packaging-unit/${id}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getPatients, getPatient } from "api/patients";
import { getDifficulties } from "api/difficulties";
import { getCategories } from "api/categories";
import { getCourses } from "api/courses";

import Modal from "components/Modal/Modal";
import PatientsTable from "components/PatientsTable/PatientsTable";
import PatientPreview from "components/PatientPreview/PatientPreview";
import Button, { ButtonGroup } from "components/Form/Button/Button";
import Input from "components/Form/Input/Input";
import Textarea from "components/Form/Textarea/Textarea";
import Toggle from "components/Form/Toggle/Toggle";
import Select from "components/Form/Select/Select";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";

import { formatDate } from "utils/utils";

const StoryModal = ({ storyData = null, isDuplicate, onSubmit, ...props }) => {
	const { t } = useTranslation();

	// table - states
	const [patients, setPatients] = useState([]);
	const [patient, setPatient] = useState(null);
	const [difficultyOptions, setDifficultyOptions] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [courseOptions, setCourseOptions] = useState([]);

	const [step, setStep] = useState(storyData === null ? 0 : 2);

	const Steps = { INTRO: 0, SELECT_PATIENT: 1, EDIT_STORY: 2 };

	const [story, setStory] = useState({
		title: storyData?.title || "",
		description: storyData?.description || "",
		patient_id: storyData?.patient_id || null,
		difficulty_id: storyData?.difficulty_id || null,
		category_id: storyData?.category_id || null,
		course_id: storyData?.course_id || null,
		is_active: storyData?.is_active ?? true, // Only default to true if undefined or null
		is_class: storyData?.is_class ?? true,
		is_self_study: storyData?.is_self_study ?? false,
		is_shared: storyData?.is_shared ?? false,
	});

	const modalTitle = !storyData
		? t("Create new story")
		: isDuplicate
		? t("Duplicate story")
		: t("Edit story");

	const modalMessage = !storyData
		? "You are going to add a story. After submitting, changes are possible until the story has been used in a case."
		: isDuplicate
		? "You are going to duplicate a story. After making the wished changes you can submit the story."
		: "You are going to edit a story. After submitting, changes are possible until the story has been used in a case.";
	const validInputs = () => {
		let result = true;

		return result;
	};

	const handleClose = () => {
		setStory(null);
		props.onClose();
	};

	const handleSubmit = () => {
		if (validInputs()) {
			onSubmit && onSubmit(story);
			setStory(null);
			props.onClose();
		}
	};

	const applyFilters = (data) => {
		// only return active elements
		return data.filter((object) => object.is_active);
	};

	// set the patient

	const handleRowClick = async (row) => {
		const descriptionStart = `${row.original.first_name} ${
			row.original.last_name
		}, ${t("born on")} ${formatDate(row.original.birth_date)}, ...`;

		setStory({
			...story,
			patient_id: row.original.id,
			description: `${descriptionStart}\n\n${story.description}`,
		});

		setPatient({ patient: row.original });
	};

	const handleRemovePatient = () => {
		setPatient(null);
		setStory({ ...story, patient_id: null });
	};

	/* set the title of a duplicated story */

	useEffect(() => {
		if (isDuplicate) {
			setStory({
				...storyData,
				title: [t("Copy of"), storyData.title].join(" "),
			});
		}
	}, [isDuplicate, storyData, t]);

	/* get all patients */

	useEffect(() => {
		const fetchPatients = async () => {
			try {
				const patientsData = await getPatients();
				setPatients(applyFilters(patientsData));
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchPatients();
	}, [t]);

	/* get the complete current patient if any */

	useEffect(() => {
		if (!story.patient_id) return;

		const fetchPatient = async () => {
			try {
				const response = await getPatient(story.patient_id);
				setPatient(response);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchPatient();
	}, [story.patient_id, t]);

	/* get the difficulties */

	useEffect(() => {
		const fetchDifficulties = async () => {
			try {
				const response = await getDifficulties();
				setDifficultyOptions(
					response.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchDifficulties();
	}, []);

	/* get the categories */

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await getCategories();
				setCategoryOptions(
					response.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchCategories();
	}, []);

	/* get the courses */

	useEffect(() => {
		const fetchCourses = async () => {
			try {
				const response = await getCourses();
				setCourseOptions(
					response.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				);
			} catch (error) {
				console.error("An error occurred:", error);
			}
		};

		fetchCourses();
	}, []);

	return (
		<Modal
			title={modalTitle}
			isOpen={props.isOpen}
			onClose={handleClose}
			className="story-modal w-[1200px] h-[700px]"
		>
			<div className="h-full max-h-full overflow-auto flex flex-col gap-8">
				<form
					className="flex-1 flex gap-6 overflow-hidden"
					onSubmit={handleSubmit}
				>
					{/* Step 0 - introductory screen */}

					{step === Steps.INTRO && (
						<div className="intro max-w-prose mx-auto flex flex-col gap-8 content-center pt-16">
							<h2>{t("Adding a new story")}</h2>
							<div className="ps-6">
								<p className="mb-2">
									{t(
										"Adding a new case or editing an existing one has two steps:",
									)}
								</p>
								<ol className="list-decimal ps-6">
									<li>
										{t(
											"Selecting a patient (optional). Selecting a patient is beneficial, since it can be automatically determined if the student chose the right patient.",
										)}
									</li>
									<li>
										{t(
											"Describing the case and it's properties (e.g. difficulty, visibility, category).",
										)}
									</li>
								</ol>
							</div>
							<Button
								className="btn-primary w-min"
								onClick={() => setStep(Steps.SELECT_PATIENT)}
							>
								{t("Continue")}
							</Button>
						</div>
					)}

					{/* Step 1 - select the patient */}

					{step === Steps.SELECT_PATIENT && (
						<div className="select-patient w-full max-h-full overflow-auto flex flex-col gap-6">
							<div className="flex gap-2">
								<span className="font-light text-base">[{t("Step")} 1/2]</span>
								<h2>{t("Select a patient")}</h2>
							</div>
							<PatientsTable
								data={patients}
								initialState={{
									hiddenColumns: ["accessor", "is_active"],
								}}
								selectedId={patient?.patient.id}
								onRowClick={handleRowClick}
							/>
						</div>
					)}

					{/* Step 2 - edit the story */}

					{step === Steps.EDIT_STORY && (
						<div className="edit-case w-full max-h-full flex flex-col gap-6 overflow-auto">
							<div className="flex gap-2">
								<span className="font-light text-base">[{t("Step")} 2/2]</span>
								<h2>{t("Edit the story")}</h2>
								<HelpTooltip text={t(modalMessage)} />
							</div>
							<div className="h-full p-1 flex flex-col gap-8">
								<Input
									id="title"
									className="w-full max-w-full"
									label={t("Title")}
									value={story.title}
									autoFocus={true}
									onChange={(e) =>
										setStory({ ...story, title: e.target.value })
									}
								/>
								<Textarea
									id="description"
									className="h-full max-h-[200px]"
									label={t("Description")}
									value={story.description}
									onChange={(e) =>
										setStory({ ...story, description: e.target.value })
									}
								/>
								<div className="flex gap-6">
									<Select
										id="difficulty_id"
										className="w-1/3"
										label={t("Difficulty")}
										value={difficultyOptions.find(
											(item) => item.value === story.difficulty_id,
										)}
										noneLabel={t("None")}
										options={difficultyOptions}
										onChange={(e) =>
											setStory({ ...story, difficulty_id: e.value })
										}
									/>
									<Select
										id="category_id"
										className="w-1/3"
										label={t("Category")}
										value={categoryOptions.find(
											(item) => item.value === story.category_id,
										)}
										noneLabel={t("None")}
										options={categoryOptions}
										onChange={(e) =>
											setStory({ ...story, category_id: e.value })
										}
									/>
									<Select
										id="course_id"
										className="w-1/3"
										label={t("Course")}
										value={courseOptions.find(
											(item) => item.value === story.course_id,
										)}
										noneLabel={t("None")}
										options={courseOptions}
										onChange={(e) => setStory({ ...story, course_id: e.value })}
									/>
								</div>

								<div className="mt-auto flex gap-x-6 gap-y-2 text-sm flex-wrap">
									<div className="w-1/5 shrink-0 flex gap-2 items-center">
										<strong>{t("Availability")}</strong>
										<HelpTooltip
											text={t("Choose where this story should be available.")}
										/>
									</div>
									<div className="flex gap-6">
										<Toggle
											id="class"
											label={t("Class study")}
											checked={story.is_class}
											onChange={(value) =>
												setStory({ ...story, is_class: value })
											}
										/>
										<Toggle
											id="self-study"
											label={t("Self study")}
											checked={story.is_self_study}
											onChange={(value) =>
												setStory({ ...story, is_self_study: value })
											}
										/>
										<div className="flex gap-2">
											<Toggle
												id="shared"
												label={t("Share with other Edups clients")}
												checked={story.is_shared}
												onChange={(value) =>
													setStory({ ...story, is_shared: value })
												}
											/>
											<HelpTooltip
												text={t(
													"Allow other UMCs or hospitals to use this story.",
												)}
											/>
										</div>
									</div>
								</div>

								<div className="flex gap-x-6 gap-y-2 text-sm flex-wrap">
									<div className="w-1/5 shrink-0 flex gap-2 items-center">
										<strong>{t("Disable story")}</strong>
										<HelpTooltip
											text={t(
												"Disabled stories are not available for new classes.",
											)}
										/>
									</div>
									<div className="flex gap-3 items-center">
										<Toggle
											id="active"
											checked={story.is_active}
											onChange={(value) =>
												setStory({ ...story, is_active: value })
											}
										/>
										<div>{t(story?.is_active ? "Enabled" : "Disabled")}</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{/* aside panel with the patient preview */}

					{(step === Steps.SELECT_PATIENT || step === Steps.EDIT_STORY) && (
						<div className="aside p-1 pt-5 flex flex-col">
							<PatientPreview title="Selected patient" patient={patient} />
							{patient && (
								<Button
									className="btn-action w-auto mt-1 self-end inline-flex gap-1 text-slate-700 hover:text-red-600 hover:opacity-100"
									onClick={handleRemovePatient}
								>
									<i className="ri-delete-bin-line"></i>
									{t("Remove patient")}
								</Button>
							)}

							{step === Steps.SELECT_PATIENT && (
								<ButtonGroup className="mt-auto justify-end">
									<Button onClick={() => setStep(Steps.INTRO)}>
										{t("Back")}
									</Button>
									<Button
										className="btn-primary"
										onClick={() => setStep(Steps.EDIT_STORY)}
									>
										{t("Continue")}
									</Button>
								</ButtonGroup>
							)}

							{step === Steps.EDIT_STORY && (
								<ButtonGroup className="mt-auto justify-end">
									<Button onClick={() => setStep(Steps.SELECT_PATIENT)}>
										{t("Back")}
									</Button>
									<Button
										className="btn-primary mt-auto self-end"
										onClick={handleSubmit}
									>
										{t("Save")}
									</Button>
								</ButtonGroup>
							)}
						</div>
					)}
				</form>
			</div>
		</Modal>
	);
};

export default StoryModal;

import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table, { Actions } from "components/Table/Table";
import { addLeadingZeros, formatDate } from "utils/utils";

const PatientsTable = ({
  data,
  actions = [],
  newAction,
  newActionLabel,
  initialState,
  onRowClick,
  ...props
}) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      [
        {
          Header: "ID",
          accessor: "id",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => addLeadingZeros(value, 5),
        },
        {
          Header: "First name",
          accessor: "first_name",
        },
        {
          Header: "Last name",
          accessor: "last_name",
        },
        {
          Header: "Date of birth",
          accessor: "birth_date",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
          Header: "Age",
          accessor: "age",
          cssClass: "text-center min-cell-width",
        },
        {
          Header: "Age group",
          accessor: "label",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => t(value),
        },
        {
          Header: "Sex",
          accessor: "gender",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) => t(value),
        },
        {
          Header: "File status",
          accessor: "is_active",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) =>
            value ? t("active") : t("not active"),
        },
        actions.length > 0 && {
          Header: "Actions",
          accessor: "accessor",
          cssClass: "actions text-center min-cell-width pr-5",
          disableSortBy: true,
          Cell: ({ row: { original } }) => (
            <Actions row={original} actions={actions} />
          ),
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  const searchFilter = useCallback((rows, columns, query) => {
    return rows.filter((row) => {
      return [
        addLeadingZeros(row.values["id"], 5) || "",
        row.values["first_name"] || "",
        row.values["last_name"] || "",
      ]
        .join(" ")
        .toLowerCase()
        .includes(query.trim().toLowerCase());
    });
  }, []);

  return (
    <>
      <Table
        columns={columns}
        data={data}
        searchLabel={t("Search by ID, first name and/or last name")}
        searchColumns={["id", "first_name", "last_name"]}
        searchFilter={searchFilter}
        searchGlobal={true}
        newAction={newAction}
        newActionLabel={newActionLabel}
        initialState={initialState}
        onRowClick={onRowClick}
        {...props}
      />
    </>
  );
};

export default PatientsTable;
